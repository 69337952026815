import React, { useEffect } from 'react'
import { useState } from 'react'
import "../../Verfiy.css"
import loader from "../Verification/verfiyImage/khiladi_adda_loader.gif"
import homeImage from "../Verification/verfiyImage/homeImage.png"

import OtpInput from 'react-otp-input';
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Verified } from "../Verification/verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "../Verification/verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "../Verification/verfiyImage/pending.svg"
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Login() {
    const [number, setNumber] = useState();
    const [otp, setOtp] = useState('');
    const [open, setOpen] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [loading, setLoading] = useState(false)

    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const [is_aadhaar, setIs_aadhaar] = useState(false);
    const jwt = JSON.parse(localStorage.getItem("JWT"));
    const [selectWinning, setSelectWinning] = useState([1])
    // const [errorType, setErrorType] = useState([0])
    const [profileData, setProfileData] = useState({});
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);
    const [jwts, setJwts] = useState(jwt)
    const [coins, setCoins] = useState();
    const [rummyCoins, setRummyCoins] = useState(false);
    const [transferType, setTransferType] = useState(0) //to deposit normal , 1 to bonus normal  , 2 via rummy
    const [transferSec, setTransferSec] = useState({})
    const [incorrectCount, setIncorrectCount] = useState(0)
    const navigate = useNavigate();
    const handleDelete = () => {
        setOpen(false)
    }

    const handleRadioClick = (index) => {
        setSelectWinning([index])
        setCoins("")
        index == 2 ? setRummyCoins(true) : setRummyCoins(false)

    };
    const getBonusPercent = async (transferCount, r_percetn_array, n_percent_array, cbWallet) => {
        function isNumeric(num) {
            return !isNaN(num)
        }
        let amt = 90
        let basicPercent = [9, 7, 5]
        console.log("rummy ", rummyCoins)
        let currentTransaction = transferCount > 3 ? 2 : transferCount == 0 ? 0 : transferCount - 1
        if (rummyCoins) {
            console.log("r_percetn_array", r_percetn_array.length)
            r_percetn_array.length > 0 ? amt = r_percetn_array[currentTransaction] : amt = basicPercent[currentTransaction]
            setTransferType(2)
        } else {
            n_percent_array.length > 0 ? amt = n_percent_array[currentTransaction] : amt = basicPercent[currentTransaction]
            n_percent_array.length > 0 && cbWallet == 2 ? setTransferType(1) : setTransferType(0)
        }
        console.log("amt", amt)
        !isNumeric(amt) ? amt = 5 : amt
        return amt
    }
    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }


    const getProfile = async () => {
        try {
            const response = await axiosClient.get("/profile?transaction=false", {
                headers: {
                    'x-access-token': jwts,
                    'token': jwts
                }
            });
            if (response.data.status === false) {
                localStorage.clear();
                window.location.replace("/login")
                return
            }
            if (response.data.status === true) {
                let bonus = await getBonusPercent(response.data?.nTimesCoinsCoverted, response?.data.response?.w_d_trans_config?.rummyPercentage, response?.data.response?.w_d_trans_config?.percentage, response?.data.response?.w_d_trans_config?.cashbackWallet)
                console.log("bonus2--> in profile iasujh", bonus)
                let datas = response.data.response;
                let saves = {
                    user_id: datas._id,
                    name: datas.name,
                    dob: datas.dob,
                    city: datas.city,
                    state: datas.state,
                    country: datas.country,
                    zip_code: datas.zip_code,
                    mobile: datas.mobile,
                    info: datas.coins,
                    winnig: response.data?.winningAmount,
                    coins_converted: bonus,
                    rummyWinning: Number(datas?.restrictedCoins).toFixed(2)

                };
                setProfileData(saves);
                localStorage.setItem("data", JSON.stringify(saves));
                return saves; // Return the saves object
            }
        } catch (error) {
            console.error("Error in getProfile:", error);
            throw error;
        }
    }

    // user login otp
    const sendOtp = () => {
        setLoading(true);
        if (!number || number.length === 0 || Number(number.slice(0, 1)) < Number(6)) {
            setLoading(false);
            handleOpen(0, "Enter your Mobile Number")
        }
        else if (number.length < 10 || !number.length) {
            setLoading(false);
            handleOpen(0, "Enter your 10 digit Mobile Number")
        }
        else if (number.length === 10) {
            console.log("number", number, typeof (number))

            const data = {
                mobile: number
            }
            axiosClient
                .post(`/auth/login`, data)
                .then((res) => {
                    setLoading(false);
                    console.log("res", res.data)
                    if (res.data.status == false) {
                        handleOpen(0, res.data.message)
                    }
                    else if (res.data.status === true) {
                        setOtpBox(true);
                        handleOpen(1, "OTP has been successfully send to your number.")
                    }
                })
                .catch((err) => setLoading(false));
        }
        else {
            setLoading(false);
            handleOpen(0, "Please fill correct number.")
        }
    }
    // user login otp submission
    const submitOtp = async () => {
        setLoading(true);
        if (otp?.length < 6) {
            setLoading(false);
            setOpen(true)
            setDialog({
                icon: 0,
                message: `Please Enter 6 Digit OTP.`
            })
            return
        }
        if (otp?.length === 6) {
            const data = { mobile: number, otp: otp }
            await axiosClient.post(`/auth/website/verify_password_otp/login`, data)
                .then((res) => {
                    setLoading(false);
                    if (res?.data?.status === true) {
                        setJwts(res.data.jwt)
                        try {
                            axiosClient.get("/profile?transaction=false", {
                                headers: {
                                    'x-access-token': res.data.jwt,

                                }
                            }).then(async (respon) => {
                                if (respon.data.status == true) {
                                    handleOpen(1, "Otp has been verified.")
                                    setIs_aadhaar(true)
                                    localStorage.setItem("MOBILE", JSON.stringify(number))
                                    localStorage.setItem("JWT", JSON.stringify(res.data.jwt))

                                    let bonus2 = await getBonusPercent(respon.data?.nTimesCoinsCoverted, respon?.data.response?.w_d_trans_config?.rummyPercentage, respon?.data.response?.w_d_trans_config?.percentage, respon?.data.response?.w_d_trans_config?.cashbackWallet)

                                    console.log("bonus2-->in peoei", bonus2)

                                    let datas = respon.data.response
                                    let saves = {
                                        user_id: datas._id,
                                        name: datas.name,
                                        dob: datas.dob,
                                        city: datas.city,
                                        state: datas.state,
                                        country: datas.country,
                                        zip_code: datas.zip_code,
                                        mobile: datas.mobile,
                                        info: datas.coins,
                                        winnig: respon.data?.winningAmount,
                                        coins_converted: bonus2,
                                        rummyWinning: Number(datas?.restrictedCoins).toFixed(2)
                                    }
                                    setProfileData(saves)
                                    console.log("saves", saves)
                                    localStorage.setItem("data", JSON.stringify(saves))
                                }
                            })
                        } catch (error) {
                            console.log("error", error)
                            handleOpen(0, "Some Technicnal Issue")
                        }

                    }
                    else {
                        setIncorrectCount(incorrectCount + 1)
                        handleOpen(0, res.data.message)
                    }
                })
                .catch((err) => {
                    setIncorrectCount(incorrectCount + 1)
                    setLoading(false);
                    handleOpen(0, "Otp Verification Failed.Please try again later")
                });
        }
    }
    const resendOtp = async () => {
        setLoading(true);
        setMinutes(0);
        setSeconds(0);
        const data = {
            mobile: number
        }
        handleOpen(2, `Sending Otp Again`)
        await axiosClient
            .post(`/auth/login`, data)
            .then((res) => {
                setLoading(false)
                if (res.data.status === false) {
                    handleOpen(0, res.data.message)
                }
                else if (res.data.status === true) {
                    setOtpBox(true);
                    handleOpen(1, res.data.message)
                }
            })
            .catch((err) => setLoading(false));

    }
    const handleFunct = async () => {
        setLoading(true);
        const updated = await getProfile()
        if ((!coins && selectWinning.includes(1))) {
            handleOpen(0, `Please Enter the winning amount.`)
            setLoading(false)
            return
        }
        if ((!rummyCoins && selectWinning.includes(2))) {
            handleOpen(0, `Please Enter the rummy amount.`)
            setLoading(false)
            return
        }
        await axiosClient.get('/public/meta')
            .then(async (res) => {
                if (selectWinning.includes(1) && (Number(coins) > Number(Number(updated?.info?.winning) - Number(updated?.winnig)))) {
                    if (Number(profileData?.rummyWinning).toFixed(2) > 0) {
                        handleOpen(0, `You have insufficient winning. You can convert Rummy Winning to Bonus.`)
                    } else {
                        handleOpen(0, `You have insufficient winning. Please try again later.`)
                    }
                    return
                }
                if (selectWinning.includes(2) && (Number(coins) > Number(profileData?.rummyWinning))) {
                    if (Number(profileData?.rummyWinning).toFixed(2) == 0) {
                        handleOpen(0, `You have insufficient winning. You can convert Other Winning to Deposit.`)
                    } else {
                        handleOpen(0, `You have insufficient winning. Please try again later.`)
                    }
                    return
                }
                let config = res.data.response.transferConfig
                if (selectWinning.includes(1) && (coins < config.min || coins > config.max)) {
                    handleOpen(0, `Transfer amount should be greater than to ${config?.min} and lesser than to ${config?.max}`)
                    return
                }
                if (selectWinning.includes(2) && (coins < config.min || coins > config.max)) {
                    handleOpen(0, `Transfer amount should be greater than to ${config?.min} and lesser than to ${config?.max}`)
                    return
                }
                // let bonus = await getBonusPercent(response.data?.nTimesCoinsCoverted, response?.data.response?.w_d_trans_config?.rummyPercentage, response?.data.response?.w_d_trans_config?.percentage)
                let bonusPercent = updated.coins_converted;
                let transferDetail = {
                    winWallet: updated.info.winning,
                    transfer: Number(coins),
                    rummyAmount: rummyCoins,
                    cbAmount: Number(coins) * bonusPercent / 100,
                    finalPay: Number(coins) + (Number(coins) * bonusPercent / 100),
                    remain: Number(updated.info.winning) - Number(coins),
                    handle: config.cashbackWallet,
                    isBonus: transferType == 1 ? true : false
                }
                setTransferSec(transferDetail)
                localStorage.setItem("transferDetail", JSON.stringify(transferDetail))
                navigate("/transfers")
            })
            .catch((err) => {
                console.log("err", err)
            })
            .finally(() => setLoading(false))
    }
    const blockInvalidChar = e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();


    useEffect(() => {
        if (otpBox) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [otpBox, seconds]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (jwts?.length > 10 || jwt) {
            setIs_aadhaar(true)
            getProfile()
            return
        }
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);

        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }

    }, [])

    return (
        <>
            <div className='verfiyMain'>
                <div className='verfiyTop'>
                    <h1>{is_aadhaar ? "User Details " : "Login"}</h1>
                </div>
                {loading ?
                    <div className='loaderKaBox'>
                        <img src={loader} alt='loader' className='loaderKA' />
                    </div>
                    : <>
                        <div className='verifyBody'>
                            {is_aadhaar ? "" :
                                <div className='homeUImage'>
                                    <img src={homeImage} alt="homeImage" />
                                </div>}

                            {
                                is_aadhaar ?
                                    <>
                                        {
                                            <div className='aadhaarDetails'>
                                                <h3>User Details</h3>
                                                <div className='basicDetail'>
                                                    <p className='detailBox'><span>Name : </span> {profileData?.name}</p>
                                                    <p className='detailBox'><span>Mobile Number : </span> {profileData.mobile}</p>
                                                    <p className='para'>Wallet Details</p>
                                                    <div className='detailFlexW'>
                                                        <div className='borderWhite fourth'>
                                                            <p>Deposit</p>
                                                            <p>{Number(profileData?.info?.deposit).toFixed(2)}</p>
                                                        </div>
                                                        <div className='borderWhite fourth'>
                                                            <p>Other Winning</p>
                                                            <p>{Number(Number(profileData?.info?.winning) - Number(profileData?.rummyWinning)).toFixed(2)}</p>
                                                        </div>
                                                        <div className='borderWhite fourth'>
                                                            <p>Rummy Winning</p>
                                                            <p>{Number(profileData?.rummyWinning).toFixed(2)}</p>
                                                        </div>
                                                        <div className='borderWhite fourth'>
                                                            <p>Bonus</p>
                                                            <p>{Number(profileData?.info?.bonus).toFixed(2)}</p>
                                                        </div>

                                                        <div
                                                            className='borderWhite half'
                                                            onClick={() => {
                                                                handleRadioClick(1);
                                                            }}
                                                        >
                                                            <div className='borderFlex w25'>
                                                                <div className='radioButton'>
                                                                    <div className={`radioFill ${selectWinning.includes(1) ? 'fill' : ''}`}></div>
                                                                </div>
                                                            </div>
                                                            <div className='borderFlex w70'>
                                                                <p>Transfer Winning to {transferType == 0 ? 'Deposit' : 'Bonus'}</p>
                                                                <p>{Number(profileData?.info?.winning) >= Number(profileData?.rummyWinning)
                                                                    ? Number(Number(profileData?.info?.winning) - Number(profileData?.rummyWinning || 0)).toFixed(2)
                                                                    : '0.00'}</p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className='borderWhite half'
                                                            onClick={() => {
                                                                handleRadioClick(2);
                                                            }}
                                                        >
                                                            <div className='borderFlex w25'>
                                                                <div className='radioButton'>
                                                                    <div className={`radioFill ${selectWinning.includes(2) ? 'fill' : ''}`}></div>
                                                                </div>
                                                            </div>
                                                            <div className='borderFlex w70'>
                                                                <p>Transfer Rummy Amount</p>
                                                                <p>{Number(profileData?.info?.winning) == 0 ? 0 : Number(profileData?.info?.winning) <= Number(profileData?.rummyWinning) ? Number(profileData?.info?.winning).toFixed(2) : Number(profileData?.rummyWinning).toFixed(2)}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='inputDetails p0'>
                                                    <p className='para'>Other Winning To Transfer</p>
                                                    <div className='inputCover'>
                                                        <input
                                                            onKeyDown={blockInvalidChar}
                                                            id="name"
                                                            type="number"
                                                            className="profileInput"
                                                            value={coins}
                                                            placeholder={"Enter amount to transfer"}
                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 6) {
                                                                    setCoins(e?.target?.value)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {/* {errorType?.includes(1) && <p className='errorMsgRummy'>* Please Select Transfer Winning Amount</p>}
                                                    {errorType?.includes(2) && <p className='errorMsgRummy'>* Please Select Transfer Rummy Amount</p>} */}
                                                    <br />
                                                    {transferType !== 2 && selectWinning.includes(1) && <>
                                                        <p className='winnPara bold'>Note : You will get {profileData?.coins_converted || 5}% cashback of the transfer amount to {transferType == 0 ? 'deposit' : 'bonus'}.</p>
                                                    </>}
                                                    {selectWinning.includes(2) && <p className='winnPara bold'>Note : Cashback from Rummy Winnings will be added to Bonus and Cashback from Other Winnings will be added to Deposit.</p>}
                                                </div>
                                                <div className='verfiysubmitCover'>
                                                    <p className='verifysubmit' onClick={() => handleFunct()}>Proceed to Transfer</p>
                                                </div>
                                            </div>
                                        }

                                    </> :
                                    <>
                                        <div className='inputDetails'>
                                            <p className='para'>Mobile Number</p>
                                            <div className='inputCover'>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    className="profileInput"
                                                    value={number}
                                                    placeholder={"Enter your Mobile Number"}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 10) {
                                                            setNumber(e.target.value)
                                                        }
                                                    }}
                                                />
                                                {
                                                    !otpBox ? <p className='otp' onClick={() => sendOtp()}>Get OTP</p> : null
                                                }

                                            </div>


                                        </div>

                                        {
                                            otpBox ? <>
                                                <div className='inputDetails'>
                                                    <p className='para'>Enter the 6 digit OTP</p>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputType="number"
                                                        inputStyle="otpInput"
                                                        renderSeparator={<span>-</span>}
                                                        renderInput={(props) => <input {...props}
                                                        />
                                                        }
                                                    />
                                                </div>
                                                <div className="countdown-text">
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p>Didn't recieve code?</p>
                                                    )}


                                                    <div

                                                        onClick={() => {
                                                            if (seconds > 0 || minutes > 0) {
                                                                return
                                                            } else {
                                                                resendOtp();
                                                            }
                                                        }}
                                                        className={seconds > 0 || minutes > 0 ? 'resendButton' : 'resendButton active'}
                                                    >  <p>
                                                            Resend OTP
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='verfiysubmitCover'>
                                                    <h3 className='verifysubmit' onClick={() => submitOtp()}>Submit</h3>
                                                </div>
                                            </> : <>
                                                <div className='newNote'>
                                                    <h3>Important Updates</h3>
                                                    <p>All Khiladis, we have updated our cashback policies which are as follows:</p>
                                                    <ol className='newNoteOl'>
                                                        <li>9% Cashback will be given on the first transaction of the day.</li>
                                                        <li>7% Cashback will be given on the second transaction of the day.</li>
                                                        <li>5% Cashback will be given on the third transaction of the day.</li>
                                                        <li>You can transfer upto Rs. 5 Lakhs in a single transaction at a time.</li>
                                                        <li>You can transfer money up to 3 times a day.</li>
                                                        <li>After Two successful Withdrawals in a day, you Won't be able to transfer Your Winnings amount in to your Deposit Wallet.</li>

                                                    </ol>
                                                    <br />
                                                    <h3>जरुरी सूचना</h3>
                                                    <p>सभी खिलाड़ियों, हमने अपनी कैशबैक नियम को अपडेट किया है जो इस प्रकार हैं:</p>
                                                    <ol className='newNoteOl'>
                                                        <li>दिन के पहले ट्रांजैक्शन पर 9% कैशबैक दिया जाएगा।</li>
                                                        <li>दिन के दूसरे ट्रांजैक्शन पर 7% कैशबैक दिया जाएगा।</li>
                                                        <li>दिन के तीसरे ट्रांजैक्शन पर 5% कैशबैक दिया जाएगा।</li>
                                                        <li>आप एक बार में 5 लाख रुपये तक ट्रांसफर कर सकते हैं।</li>
                                                        <li>आप दिन में 3 बार पैसे ट्रांसफर कर सकते हैं।</li>
                                                        <li>एक दिन में दो सफल निकासी के बाद, आप अपनी जीत की राशि को अपने जमा वॉलेट में ट्रांसफर नहीं कर पाएंगे।</li>

                                                    </ol>
                                                </div></>
                                        }
                                    </>
                            }



                        </div>
                    </>}
            </div >

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDelete()}>Okay</div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default Login